@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes flicker {
  to {
    mask-position:
      50% 50%,
      0 50%;
  }
}

html {
  font-family: lucidaconsole, monospace;
  font-size: 16px;
  color: #111;
  /* background-color: rgb(246, 251, 255); */
  max-width: 1280px;
  min-height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  /* @apply bg-gradient-to-r from-stone-100 to-zinc-200; */
  background-image: 
    linear-gradient(to left,
    rgba(215, 229, 255, 0.585),
    rgba(255, 255, 255, 1)
  );
  background-color: #fff;
  background-position:
    20px 20px,
    15px 15px;
  background-size: 10px 10px;
}

ol {
  list-style: decimal;
}

div[id='root'] {
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.5) 80%,
    rgba(255, 255, 255, 1)
  );
}

input {
  outline: none;
}

@layer components {
  .btn-header {
    @apply w-full rounded-sm border border-gray-200 bg-white px-2 py-2 text-sm text-black shadow-[inset_0_-2px_1px_1px_rgba(0,0,0,0.15)] transition-all hover:bg-gray-200 active:shadow-none lg:w-fit lg:px-4 lg:text-[16px];
  }

  .btn-primary {
    @apply w-full rounded-sm border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-800 transition-colors duration-200 hover:bg-gray-100 lg:w-fit;
  }

  .btn-disabled {
    @apply w-full rounded-sm border border-gray-300 bg-gray-100 px-4 py-2 text-base font-medium text-gray-400 transition-colors duration-200 hover:bg-gray-100 lg:w-fit;
  }

  .device-card-group {
    @apply absolute right-2 top-[-0.5rem] rounded-md border border-zinc-300 bg-white px-2 text-sm text-gray-700;
  }

  .device-card-info {
    @apply max-w-full whitespace-normal break-words text-gray-600;
  }

  .device-card-buttons-section {
    @apply flex w-full flex-col justify-start space-x-0 space-y-2 text-sm md:flex-row md:space-x-2 md:space-y-0;
  }

  .device-card-button {
    @apply w-full rounded-md border border-zinc-300 bg-white px-2 py-1 text-gray-800 hover:bg-stone-100;
  }
}

.bg-close {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40px' height='40px' viewbox='0 0 40 40'%3E%3Cpath d='M 10,10 L 30,30 M 30,10 L 10,30' stroke='black' stroke-width='3' stroke-linecap='butt' /%3E%3C/svg%3E");
  background-size: 100%;
}

/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: gray;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

.multi-line-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word; /* Breaks long words */
  overflow-wrap: break-word; /* Ensures long words wrap */
}

/* Green Checkmark */
.checkmark {
  display: inline-block;
  width: 10px;
  height: 10px;
  position: relative;
}

.checkmark::after {
  content: '';
  position: absolute;
  left: 2px;
  top: -2px;
  width: 6px;
  height: 10px;
  border: solid gray;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Red Crossmark */
.crossmark {
  display: inline-block;
  width: 10px;
  height: 10px;
  position: relative;
}

.crossmark::before,
.crossmark::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: rgb(179, 93, 93);
  top: 50%;
  left: 3px;
}

.crossmark::before {
  transform: rotate(45deg);
}

.crossmark::after {
  transform: rotate(-45deg);
}

.plus-sign {
  position: relative;
  width: 10px; /* Size of the plus sign */
  height: 10px; /* Size of the plus sign */
  background-color: transparent;
}

.plus-sign::before,
.plus-sign::after {
  content: '';
  position: absolute;
  background-color: green; /* Color of the plus sign */
}

.plus-sign::before {
  width: 100%; /* Length of the horizontal line */
  height: 2px; /* Thickness of the horizontal line */
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.plus-sign::after {
  width: 2px; /* Thickness of the vertical line */
  height: 100%; /* Length of the vertical line */
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
