.codemirror-editor {
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
}

@media screen and (max-width: 768px) {
  .codemirror-editor {
    width: 100%;
  }
}

/* Ensure the editor content wraps */
.CodeMirror pre {
  white-space: pre-wrap; /* Wrap long lines */
  word-break: break-all; /* Break long words */
}
