.video-container {
  position: absolute;
  height: 50dvh;
  z-index: -1;
  display: none;
  overflow: hidden;
}
.video-container.active {
  display: block;
}
video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-top: -100px;
}

.blur-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 100px);
  /* backdrop-filter: blur(2px);  */
  /* background: rgba(0, 0, 0, 0.3);  */
  z-index: 1;
}

.yandex-gradient {
  background: -webkit-linear-gradient(left, rgb(170, 0, 0), blue);
  background: -o-linear-gradient(right, red, blue);
  background: -moz-linear-gradient(right, red, blue);
  background: linear-gradient(to right, red, blue);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.advantage-gradient {
  -size: 1.05rem;
  background: -webkit-linear-gradient(left, rgb(0, 68, 255), rgb(0, 126, 21));
  background: -o-linear-gradient(right, rgb(0, 68, 255), rgb(0, 126, 21));
  background: -moz-linear-gradient(right, rgb(0, 68, 255), rgb(0, 126, 21));
  background: linear-gradient(to right, rgb(0, 68, 255), rgb(0, 126, 21));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#logo-landing {
  z-index: 1;
}

#login-button {
  animation: pulse 2s infinite;
  -webkit-animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    color: #000000;
    background-color: #f9f9f9;
  }

  50% {
    color: #4caf50;
    background-color: #eee;
  }

  100% {
    color: #000000;
    background-color: #f9f9f9;
  }
}

.links-container {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

.links-container a {
  text-decoration: none;
  margin: 0 10px;
  padding: 0;
  margin: 0;
}

#logo-landing svg {
  width: 100%;
  height: auto;
}

hr {
  border: none;
  height: 2px;
  background-color: lightgrey;
  width: 100%;
  margin: 30px auto;
  display: block;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  unicode-bidi: isolate;
  overflow: hidden;
}

h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  unicode-bidi: isolate;
}

ul {
  list-style-type: square;
  padding-left: 20px;
}
li {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}
.features {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 720px) {
  .features {
    display: block;
  }
}

.feature {
  width: 48%;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
}

@media screen and (max-width: 720px) {
  .feature {
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
}

.feature h3 {
  color: #444;
  margin-top: 0;
}

.feature p {
  color: #777;
}

.advantages {
  margin-top: 20px;
  background-color: #e9f7e9;
  padding: 10px;
  margin-bottom: 10px;
  border-left: 5px solid #4caf50;
}
