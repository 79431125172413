@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer utilities {
  .striped > div:nth-child(even) {
    @apply bg-neutral-50
  }
} */

.Toastify__toast {
  width: fit-content !important;
  background-color: #ffffff;
  color: #1a1a1a;
  border-radius: 12px;
  padding: 14px 20px;
  font-family: 'Lucida Console', monospace;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
}

.Toastify__toast-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: fit-content !important;
}

.Toastify__toast--info {
  background-color: #e0f7ff;
  border-color: #b3e5fc;
  color: #0066cc;
}

.Toastify__toast--success {
  background-color: #e8f5e9;
  border-color: #c8e6c9;
  color: #2e7d32;
}

.Toastify__toast--error {
  background-color: #ffebee;
  border-color: #ffcdd2;
  color: #c62828;
}

.Toastify__toast--warning {
  background-color: #fff8e1;
  border-color: #ffe082;
  color: #f57c00;
}

.Toastify__close-button {
  color: #666;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.Toastify__close-button:hover {
  opacity: 1;
  color: #333;
}

.Toastify__progress-bar {
  background-color: rgba(0, 0, 0, 0.1);
}

.Toastify__progress-bar--info {
  background-color: rgba(0, 102, 204, 0.2);
}

.Toastify__progress-bar--success {
  background-color: rgba(46, 125, 50, 0.2);
}

.Toastify__progress-bar--error {
  background-color: rgba(198, 40, 40, 0.2);
}

.Toastify__progress-bar--warning {
  background-color: rgba(245, 124, 0, 0.2);
}

.rotate-and-clip {
  transform: rotate(90deg);
}

.notification-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  background-color: #ff4757;
  border-radius: 10px;
  margin-left: 8px;
}
