/* #mainContainer {
    min-width: 704px;
} */

.Button_blue {
  color: white !important;
  fill: white !important;
  background-color: #3baafb !important;
}

.Button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 7px 12px;
  font-size: 14px;
  font-weight: bold;
  color: #3baafb;
  fill: #3baafb;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 8px;
  box-sizing: border-box;
  outline: 0;
  cursor: pointer;
  transition: 0.3s;
}

form {
  display: block;
  margin-top: 0em;
  unicode-bidi: isolate;
}

.InputUI {
  display: block;
  border-bottom: 1px solid #e6ecef;
  cursor: text;
  transition: 0.3s;
}

.InputUI-Label {
  font-size: 12px;
  color: #666970;
  transition: 0.3s;
}

.InputUI-Inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  transition: 0.3s;
}

.InputUI-Input {
  width: 100%;
  padding-right: 16px;
  font-size: 14px;
  color: #666970;
  box-sizing: border-box;
  transition: 0.3s;
}

.Popup-Button {
  /* width: -moz-max-content; */
  width: max-content;
  margin-top: 16px;
}

.buttonImage {
  max-width: 16px;
  max-height: 16px;
}

.Button_frimeware {
  padding-left: 0 !important;
  justify-content: flex-start !important;
}
